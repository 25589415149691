
import Vue from 'vue';
import {Component} from "vue-property-decorator";
import {namespace} from "vuex-class";
import Company from "@/models/Company";
import {COMPANY_STORE_NAME, CompanyStoreActions} from "@/store/company.store";

const CompanyStore = namespace(COMPANY_STORE_NAME);

@Component({
  components: {
    CompanyListComponent: () => import(
      /* webpackChunkName: "CompanyListView" */
      '@/components/Companies/CompanyList.component.vue'
    ),
  }
})
export default class CompaniesView extends Vue {
  /**
   * action to get all companies from the api
   */
  @CompanyStore.Action(CompanyStoreActions.GET_ALL)
  private loadCompanies!: () => Promise<Company[]>;

  /**
   * list of all companies
   */
  private companies: Company[] = [];

  /**
   * flag that indicates if the list is loading
   * @private
   */
  private isLoading: boolean = false;

  /**
   * query string for the list of companies
   * @private
   */
  private search: string = '';

  /**
   * loads all companies on created
   */
  async created() {
    try {
      this.isLoading = true;
      this.companies = await this.loadCompanies();
    } finally {
      this.isLoading = false;
    }
  }
}
